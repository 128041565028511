<div class="ax-office-favorites-bar">
  <div class="flex gap-4">
    @for (favorite of favorites$ | async; track favorite) {
      <ax-ui-button (buttonClick)="navigate(favorite)"
                    [small]="true"
                    [text]="favorite.label"
                    [tooltipDescription]="favorite.fragment ? favorite.fragment : ''"
                    variant="tertiary" />
    }
    @if (isCurrentNavItemFavorite) {
      <ax-ui-button (buttonClick)="toggle()"
                    [iconOnly]="true"
                    [small]="true"
                    iconName="star" variant="secondary" />
    }
    @if (!isCurrentNavItemFavorite) {
      <ax-ui-button (buttonClick)="toggle()"
                    [disabled]="(favorites$ | async)?.length === 5"
                    [iconOnly]="true"
                    [small]="true"
                    [tooltipDescription]="'MAXIMALE_ANZAHL_FAVORITEN' | translate"
                    iconName="star"
                    variant="tertiary" />
    }
  </div>
</div>
